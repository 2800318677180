import Layout from "app/core/layouts/Layout"
import { GetServerSidePropsContext, InferGetServerSidePropsType } from "blitz"
import OrgPage from "app/org/components/orgPage"
import Landing from "app/core/components/Landing"
import { orgPageGetServerSideProps } from "app/lib/orgPageGssp"

const Home = (props: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  return props.isHome ? <Landing /> : <OrgPage />
}

Home.suppressFirstRenderFlicker = true
Home.getLayout = (page) => <Layout>{page}</Layout>

export const getServerSideProps = async (ctx: GetServerSidePropsContext) => {
  return await orgPageGetServerSideProps(ctx)
}

export default Home
