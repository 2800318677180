import { chakra } from "@chakra-ui/react"
import { Image as NextImage } from "next/image"

import { Box, Text, Link, Input, InputGroup, InputRightElement, Button } from "@chakra-ui/react"
import styled from "@emotion/styled"

// export const RasterImage = chakra(NextImage, {
//   baseStyle: { maxH: 120, maxW: 120 },
//   shouldForwardProp: (prop) => ["width", "height", "src", "alt"].includes(prop),
// })

const Landing = () => (
  <Box>
    <Text as="h1" fontSize={60} fontFamily="serif" fontWeight="semibold">
      Noble
    </Text>

    <Text as="p" fontSize={20} fontFamily="serif" letterSpacing={0.7}>
      /ˈnō-bəl/ &nbsp;&nbsp;
      <i>
        <strong>adjective</strong>
      </i>
    </Text>

    <Text as="p" fontSize={25} fontFamily="serif" fontStyle="italic" mt={2} mb={6}>
      Authorization for DAOs
    </Text>

    <Text>
      We live in a world where centralized cloud software is depended on by humans for communication
      and collaboration.
    </Text>

    <Text mb={8}>
      We are aligning this software to the structures of DAOs to give sovereignty to organizations
      and their members.
    </Text>

    <Text as="p" fontSize={16} mb={1}>
      <OrgLink href="/sound">Sound.xyz</OrgLink>, <OrgLink href="/zora">Zora</OrgLink>,{" "}
      <OrgLink href="/citydao">CityDAO</OrgLink>, <OrgLink href="/ens">ENS</OrgLink>.
    </Text>

    <Text as="p" fontSize={16} mb={8}>
      Open Roles:{" "}
      <OrgLink href="https://nobleplace.notion.site/Senior-Product-Engineer-a650c37c8d684cb2a03d33ea11a39cb7">
        Senior Product Engineer
      </OrgLink>
    </Text>

    <Text fontSize={14} fontWeight="semibold" mb={8}>
      info@noble.place / work@noble.place
    </Text>

    <Text fontSize={14} mb={8} color="#777">
      © Beta Exploration Inc
    </Text>

    {/* <InputGroup size="md">
      <Input pr="4.5rem" type={show ? "text" : "password"} placeholder="Enter password" />
      <InputRightElement width="4.5rem">
        <Button h="1.75rem" size="sm" onClick={handleClick}>
          {show ? "Hide" : "Show"}
        </Button>
      </InputRightElement>
    </InputGroup> */}
  </Box>
)

const OrgLink = styled(Link)`
  text-decoration: underline;
  // margin-right
  &:hover {
    text-decoration: none !important;
  }
`

export default Landing
